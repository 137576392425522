import React from 'react'

import Layout from '../components/layout'

const TermsPage = () => {
    return (
        <Layout>
          
          <div className="relative overflow-hidden py-16">
          <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
            <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
              <svg
                className="absolute top-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-lg">
                <h1>
                    <span className="block text-center text-lg font-semibold text-purple-600">Last Revised: Aug 18,2022</span>
                    <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Terms of Service
                    </span>
                </h1>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-15 lg:px-8 xl:pl-12">
                    <p className="max-w-3xl text-lg text-gray-900">
                        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.
                        <br/><br/>
                        These terms of service (“Terms of Service”) are a legal agreement (“Agreement”) between you, either an individual or a single legal entity (“You” or “you”), and Shieldstack. (“Shieldstack” or the “Company”, together with its Affiliates, collectively the “Shieldstack Party”).
                        <br/><br/>
                        This Agreement sets forth the legally binding terms and conditions for your use of the various services and websites owned and operated by Company, including, without limitation, the shieldstack.io, app.shieldstack.io websites and domain names (“Sites”), and any other features, content, or applications offered from by Company in connection therewith, including, but not limited to, website integrity, monitoring, alerting, and cleanup. (Collectively “Service”).
                        <br/><br/>
                        By clicking on the “I agree” button, completing the registration process, and/or using the Sites or Service in any manner, including but not limited to visiting or browsing the Sites, you represent that (1) you have read, understand, acknowledge, and agree to be bound by this Agreement, (2) you are of legal age to form a binding contract with the company, and (3) you have the authority to enter into this Agreement personally or on behalf of the company you have named as the customer, and to bind that company to these terms.
                        <br/><br/>
                        This Agreement applies to all users of the Sites or Service, including users who are also contributors of content, information, and other materials or services on the sites. If you do not agree to be bound by this Agreement, you may not access or use the Sites or the Service.
                        <br/><br/>
                        If, after your electronic acceptance of this Agreement, Company finds that you do not have the legal authority to bind such corporate entity, you will be personally responsible for the obligations contained in this Agreement, including, but not limited to, the payment obligations. Company shall not be liable for any loss or damage resulting from Company’s reliance on any instruction, notice, document or communication reasonably believed by Company to be genuine and originating from an authorized representative of your corporate entity. If there is reasonable doubt about the authenticity of any such instruction, notice, document or communication, Company reserves the right (but undertakes no duty) to require additional authentication from you. You further agree to be bound by the terms of this Agreement for transactions entered into by you, anyone acting as your agent and anyone who uses your account or the Service, whether or not authorized by you.
                    </p>
                    <h4 className="text-lg font-bold my-4">Acceptance of Terms</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        The Service is offered subject to acceptance without modification of the Terms of Service and all other operating rules, policies and procedures that may be published from time to time on the Sites by Company.
                        <br/><br/>
                        In addition, some services offered through the Service may be subject to additional terms and conditions promulgated by Company from time to time (the “Supplemental Terms”); your use of such services is subject to those Supplemental Terms, which are incorporated into these Terms of Service by this reference and are referred to collectively as the “Terms.”
                        <br/><br/>
                        If these Terms of Service are inconsistent with the Supplemental Terms for any Service, the Supplemental Terms will control with respect to that Service.
                    </p>
                    <h4 className="text-lg font-bold my-4">Modification</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        Company may, in its sole and absolute discretion, change or modify this Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or modifications shall be effective immediately upon posting on the Sites. Your use of the Sites or the Service after such changes or modifications have been made shall constitute your acceptance of this Agreement as last revised. If you do not agree to be bound by this Agreement as last revised, do not use (or continue to use) the Sites or the Service. In addition, Company may occasionally notify you of changes or modifications to this Agreement by email. It is therefore very important that you keep your Account information current. Company assumes no liability or responsibility for your failure to receive an email notification if such failure results from an inaccurate email address.
                    </p>
                    <h4 className="text-lg font-bold my-4">Customer Account and Registration</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        As a Registered User, you are required to establish an Account and receive or establish a password (“Password”) which can be used by each of your employees and consultants who are authorized by you to use the Service on your behalf. In registering for the Service, you agree to provide true, accurate, current and complete information about yourself as prompted by our registration form (the “Registration Data”) and to maintain and promptly update the Registration Data to keep it true, accurate, current and complete.
                        <br/><br/>
                        If you provide any Registration Data that is untrue, inaccurate, not complete or incomplete, or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate your Account and to refuse any and all current or future use of the Service (or any portion thereof) by you. You are responsible for all activities that occur under your Account and your Password. You agree to notify Company immediately of any unauthorized use of your Account or Password or any other breach of security and to exit from your Account at the end of each session. You agree that you are responsible for any losses arising out of the unauthorized use of your Account.
                    </p>
                    <h4 className="text-lg font-bold my-4">Affiliate Assignment</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                         Shieldstack reserves the right, under its sole discretion, to (1) assign the Sites or Service to any of its Affiliates, including under any services agreement; and (2) change the Shieldstack Party providing Service. For the purposes of this Agreement, “Affiliate” means with respect to a party, any entity that directly or indirectly controls, is control by, or is under common control with that party.
                    </p>
                    <h4 className="text-lg font-bold my-4">Electronic Communications</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        When you visit the Sites or send e-mails to us, you are communicating with us electronically. By doing this, you consent to receive communications from us electronically. We will communicate with you via e-mail or by posting notices on the Sites. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                    <br/><br/>
                        NOTWITHSTANDING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT E-MAIL COMMUNICATION IS NOT SECURE, AND THEREFORE YOU AGREE THAT YOU WILL USE THE COMPANY’S TICKETING SYSTEM TO EXCHANGE ALL CREDENTIALS, TECHNICAL INFORMATION AND SERVICE SPECIFIC SUPPORT REQUESTS (INCLUDING BUT NOT LIMITED TO ANY MALWARE REMOVAL REQUESTS FOR COVERED WEBSITES (EACH, A “MALWARE REMOVAL REQUEST”).
                    </p>
                    <h4 className="text-lg font-bold my-4">Fees and Payment</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        You will be responsible for payment of the applicable fee (the “Service Subscription Fee”) at the time you create your Account and select your annual package (the “Service Commencement Date”). All fees will be billed to the credit card or PayPal account you designate during the registration process.
                    <br/><br/>
                        If you want to designate a different credit card or if there is a change in your credit card or PayPal account status, you must change your information online at https://app.shieldstack.io or send an e-mail to Sales.
                    </p>
                    <h4 className="text-lg font-bold my-4">Auto-Renewal</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        IN ORDER TO ENSURE THAT YOU DO NOT EXPERIENCE AN INTERRUPTION OR LOSS OF SERVICE, ALL SERVICES ARE OFFERED ON AUTOMATIC RENEWAL. EXCEPT FOR REASONS DESCRIBED BELOW IN THIS SECTION, AUTOMATIC RENEWAL AUTOMATICALLY RENEWS THE APPLICABLE SERVICE UPON EXPIRATION OF THE THEN CURRENT TERM FOR A RENEWAL PERIOD EQUAL IN TIME TO THE MOST RECENT SERVICE PERIOD. FOR EXAMPLE, IF YOUR LAST SERVICE PERIOD IS FOR ONE YEAR, YOUR RENEWAL PERIOD WILL TYPICALLY BE FOR ONE YEAR. HOWEVER, IN THE EVENT RENEWAL WITH THE PAYMENT METHOD ON FILE FAILS, COMPANY MAY ATTEMPT TO RENEW THE APPLICABLE SERVICE FOR A PERIOD LESS THAN THE ORIGINAL SUBSCRIPTION PERIOD TO THE EXTENT NECESSARY FOR THE TRANSACTION TO SUCCEED.
                        <br/><br/>
                        UNLESS YOU DISABLE THE AUTOMATIC RENEWAL OPTION, COMPANY WILL AUTOMATICALLY RENEW THE APPLICABLE SERVICE WHEN IT COMES UP FOR RENEWAL (“RENEWAL COMMENCEMENT DATE”) AND WILL TAKE PAYMENT FROM THE PAYMENT METHOD YOU HAVE ON FILE WITH COMPANY AT COMPANY’S THEN CURRENT RATES, WHICH YOU ACKNOWLEDGE AND AGREE MAY BE HIGHER OR LOWER THAN THE RATES FOR THE ORIGINAL SERVICE PERIOD. IF YOU DO NOT WISH FOR ANY SERVICE TO AUTOMATICALLY RENEW, YOU MAY ELECT TO CANCEL RENEWAL, IN WHICH CASE, YOUR SERVICES WILL TERMINATE UPON EXPIRATION OF THE THEN CURRENT TERM, UNLESS YOU MANUALLY RENEW YOUR SERVICES PRIOR TO THAT DATE (IN WHICH CASE THE SERVICES WILL AGAIN BE SET TO AUTOMATIC RENEWAL). IN OTHER WORDS, SHOULD YOU ELECT TO CANCEL YOUR PRODUCT AND FAIL TO MANUALLY RENEW YOUR SERVICES BEFORE THEY EXPIRE, YOU MAY EXPERIENCE AN INTERRUPTION OR LOSS OF SERVICE, AND COMPANY SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY REGARDING THE SAME.
                    </p>
                    <h4 className="text-lg font-bold my-4">Termination</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        You will have thirty (30) days from the Service Commencement Date or any Renewal Commencement Date to cancel the Service (the “Cancellation Period”), in which case the Company will refund your Service Subscription Fee for the applicable Service Term provided that you have not submitted a Malware Removal Request during the Cancellation Period.
                        <br/><br/>
                        You agree that you will make any cancellation request by way of a general request ticket that you submit through the Company’s ticketing system. In the event that you are using a PayPal account to make payments hereunder, you must also log in and cancel your recurring payments via your PayPal interface. Except as set forth above, your Service Subscription Fee shall be non-refundable.
                    </p>
                    <h4 className="text-lg font-bold my-4">Third Party Sites</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        The Sites or the Service may permit you to link to other websites or resources on the Internet, and other websites or resources may contain links to the Sites. When you access third party websites, you do so at your own risk. These other websites are not under Company’s control, and you acknowledge that Company is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply endorsement by Company or any association with its operators. You further acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods or services available on or through any such website or resource.
                    </p>
                    <h4 className="text-lg font-bold my-4">General</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        International Users. The Sites can be accessed from countries around the world and may contain references to services and content that are not available in your country. These references do not imply that Company intends to announce such services or content in your country. The Sites and the Service are controlled and offered by Company from its facilities in the United States of America. Company makes no representations that the Sites or the Service are appropriate or available for use in other locations. Those who access or use the Sites or the Service from other jurisdictions do so at their own volition and are responsible for compliance with local law.
                        <br/><br/>
                        <strong>Notice.</strong> Where Company requires that you provide an e-mail address, you are responsible for providing Company with your most current e-mail address. In the event that the last e-mail address you provided to Company is not valid, or for any reason is not capable of delivering to you any notices required/permitted by the Terms, Company’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice. You may give notice to Company addressed to the attention of Chief Operating Officer, 6060 Center Dr. Suite 500, Los Angeles CA 90045. Such notice shall be deemed given when received by Company by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail.
                        <br/><br/>
                        <strong>Waiver.</strong> Any waiver or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
                        <br/><br/>
                        <strong>Assignment.</strong> These Terms, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation or transfer in violation of the foregoing will be null and void.
                        <br/><br/>
                        <strong>English Language Controls.</strong> This Agreement, along with all policies and the applicable product agreements identified above and incorporated herein by reference (collectively, the “Agreement”), is executed in the English language. To the extent any translation is provided to you, it is provided for convenience purposes only, and in the event of any conflict between the English and translated version, where permitted by law, the English version will control and prevail. Where the translated version is required to be provided to you and is to be considered binding by law (i) both language versions shall have equal validity, (ii) each party acknowledges that it has reviewed both language versions and that they are substantially the same in all material respects, and (iii) in the event of any discrepancy between these two versions, the translated version may prevail, provided that the intent of the Parties has been fully taken into consideration.
                    </p>                    
                </div>            
            </div>        
          </div>
        </div>
    
        </Layout>
      )
}

export default TermsPage